<script setup>

import Button from 'primevue/button';

defineProps({
    externalLoginProviders: Array
})

const emit = defineEmits(['externalLogin'])

// Methods
const externalLogin = (providerId, providerName) => {
    emit('externalLogin', providerId, providerName)
}

</script>

<template>
    <div>
        <div class="flex flex-wrap justify-between items-center">
            <div v-for="provider in externalLoginProviders" :key="provider.Id">
                <Button :id="provider.Id" :name="provider.Settings.Caption" :title="'Log in using your ' + provider.Settings.Caption + ' account'" 
                    @click="externalLogin(provider.Id, provider.Settings.Caption)" severity="help"
                    :pt-options="{ 
                        mergeProps: true
                    }" 
                    :pt="{ 
                        root: { 
                            class: 'border border-textOnBackground px-2 py-1 lg:items-center' 
                        } 
                    }">
                    <img class="h-6 mr-2" :src="'data:image/png;base64,' + provider.Icon" :alt="provider.Settings.Caption" />
                    <span class="font-medium">
                        {{ provider.Settings.Caption }}
                    </span>
                </Button>
            </div>
        </div>
        <h3 class="splitline text-textOnBackground">
            or
        </h3>
    </div>
</template>

﻿import { createApp } from 'vue'
import VueCookies from 'vue-cookies'
import Primevue from 'primevue/config'
import { primeVueOptions } from '../../plugins/primevue'
import ToastService from 'primevue/toastservice';
import { loadFonts } from '../../plugins/webfontloader'

import Login from '@/components/login'

const authServerUrl = document.getElementById("authServerUrl").value;
const baseUrl = document.getElementById("baseUrl").value;
const brandFolderName = document.getElementById("brandFolderName").value;
const brandId = document.getElementById("brandId").value;
const companyLogo = document.getElementById("companyLogo").value;
const companyName = document.getElementById("companyName").value;
const displayRegister = document.getElementById("displayRegister").value === "True" ? true : false;
let errorToDisplay = document.getElementById("errorToDisplay").value;
if (errorToDisplay === "") {
    errorToDisplay = null;
}
const externalLoginProviders = JSON.parse(document.getElementById("externalLoginProviders").value);
const loginTitle = document.getElementById("loginTitle").value;
const loginInstruction = document.getElementById("loginInstruction").value;
const loginAdditional = document.getElementById("loginAdditional").value;
const mobileApp = document.getElementById("mobileApp").value === "True" ? true : false;
const returnUrl = document.getElementById("returnUrl").value;
const systemDisabled = document.getElementById("systemDisabled").value === "True" ? true : false;
const systemName = document.getElementById("systemName").value;
const systemUrl = document.getElementById("systemUrl").value;
const username = document.getElementById("username").value;


const props = {
    authServerUrl: authServerUrl,
    baseUrl: baseUrl,
    brandFolderName: brandFolderName,
    brandId: brandId,
    companyLogo: companyLogo,
    companyName: companyName,
    displayRegister: displayRegister,
    errorToDisplay: errorToDisplay,
    externalLoginProviders: externalLoginProviders,
    loginTitle: loginTitle,
    loginInstruction: loginInstruction,
    loginAdditional: loginAdditional,
    mobileApp: mobileApp,
    returnUrl: returnUrl,
    systemDisabled: systemDisabled,
    systemName: systemName,
    systemUrl: systemUrl,
    username: username
}

const app = createApp(Login, props)
loadFonts()
app.use(Primevue, primeVueOptions)
app.use(ToastService)
app.use(VueCookies)
app.mount('#login')

﻿import { computed, reactive } from 'vue'

export const store = reactive({
    brandId: 1,
    currentTheme: 'light',
    mobileApp: false,

    changeTheme(newTheme) {
        this.currentTheme = newTheme
    },
    setBrandId(id) {
        this.brandId = id
    },
    setMobileApp(isMobileApp) {
        this.mobileApp = isMobileApp
    },
    brandThemeClass: computed(() => {
        if (store.currentTheme === 'mobileApp') return 'mobileApp'
        return store.currentTheme === 'light' ? store.lightThemeClass : store.darkThemeClass
    }),
    darkThemeClass: computed(() => {
        return store.brandId === 1 ? 'dark' : 'coachdark'
    }),
    lightThemeClass: computed(() => {
        return store.brandId === 1 ? 'light' : 'coachlight'
    }),
})
import axios from "axios"

export const axiosClient  = () => {
    const validationTokenElement = document.querySelector('input[name="__RequestVerificationToken"]')
    let validationToken = ''
    if (validationTokenElement != null) {
        validationToken = validationTokenElement.getAttribute('value')
    }
    const headers = validationToken != null && validationToken != '' ?
        { 
            'X-Requested-With': 'XMLHttpRequest',
            'RequestVerificationToken': validationToken
        } :
        {
            'X-Requested-With': 'XMLHttpRequest'
        }
    axios.defaults.headers.post = headers
    return axios.create()
}
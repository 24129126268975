<script setup>

const props = defineProps({
    error: Boolean
})

let hintClasses = 'leading-none text-[80%] mt-2 pl-4'
if (props.error) {
    hintClasses = `${hintClasses} text-error`
}

</script>

<template>
    <div :id="($attrs.id)" :class="hintClasses" aria-live="polite" aria-atomic="true">
        {{ $attrs.text }}
    </div>
</template>